import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { formatMoney } from '../../helpers';

const PaymentAmountAfterNegativeSurplusCell = ({ money }) => (
  <Stack alignItems="flex-start" justifyContent="center" width="100%">
    <Stack spacing={0.5} direction="row">
      {formatMoney(money, true)}
    </Stack>
  </Stack>
);

PaymentAmountAfterNegativeSurplusCell.propTypes = {
  money: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }).isRequired,
};

export default PaymentAmountAfterNegativeSurplusCell;
