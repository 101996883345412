import React from 'react';
import PropTypes from 'prop-types';
import { useGetCountryFromUrl } from '../../hooks';
import ChileOperationalAlertsArray from './operational-alerts-array/ChileOperationalAlertsArray';
import MexicoOperationalAlertsArray from './operational-alerts-array/MexicoOperationalAlertsArray';

const OperationalAlertsArray = ({ invoice }) => {
  const country = useGetCountryFromUrl();
  const countryComponentMapper = {
    Chile: <ChileOperationalAlertsArray invoice={invoice} />,
    Mexico: <MexicoOperationalAlertsArray invoice={invoice} />,
  };
  return country?.name && countryComponentMapper[country.name];
};

OperationalAlertsArray.propTypes = {
  invoice: PropTypes.shape({
    hasPaymentsEmitted: PropTypes.bool,
    isFunding: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      socialReason: PropTypes.string,
    }),
    isRatificated: PropTypes.bool,
    lightningPaymentAuthorized: PropTypes.bool,
  }).isRequired,
};

export default OperationalAlertsArray;
