import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Warning from '@mui/icons-material/Warning';
import CompanyBlacklistComponent from './CompanyBlacklistComponent';
import CompanyDebtsSummary from './CompanyDebtsSummary';

const MasterEntityNameCell = ({ masterEntity, loanedStatus, loanedTo, showBlacklist, type }) => (
  <Stack width="100%" alignItems="flex-start" justifyContent="center">
    <Stack direction="row">
      <Typography variant="body2" width="inherit" noWrap>
        {masterEntity.name.length ? masterEntity.name : 'Sin nombre'}
      </Typography>
      {showBlacklist && (
      <CompanyBlacklistComponent
        masterEntity={masterEntity}
        disableDisplay
      />
      )}
      <CompanyDebtsSummary masterEntity={masterEntity} type={type} />
    </Stack>
    {['LOANED', 'LOANED_FINGO'].includes(loanedStatus) && (
      <Stack width="100%" direction="row" alignItems="center" justifyContent="flex-start">
        <Warning color="warning" />
        <Typography noWrap variant="caption">
          Cedida {loanedTo && `a ${loanedTo}`}
        </Typography>
      </Stack>
    )}
  </Stack>
);

MasterEntityNameCell.propTypes = {
  type: PropTypes.oneOf(['company', 'receiver']),
  showBlacklist: PropTypes.bool,
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  loanedStatus: PropTypes.string,
  loanedTo: PropTypes.string,
};

MasterEntityNameCell.defaultProps = {
  type: 'company',
  showBlacklist: false,
  loanedStatus: '',
  loanedTo: '',
};

export default MasterEntityNameCell;
