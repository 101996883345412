import React from 'react';
import Bolt from '@mui/icons-material/Bolt';
import { green } from '@mui/material/colors';
import { InvoiceType } from '../../../propTypes';
import AlertsGrid from '../../grids/AlertsGrid';

const ChileCommercialAlertsArray = ({ invoice }) => {
  const alertsArray = [
    {
      value: invoice.isForOrdering,
      label: 'ordering',
      color: 'primary.main',
      tooltip: 'Descuento de Ordering',
      icon: 'OC',
    },
    {
      value: invoice.lightningPaymentAuthorized,
      label: 'lightning-payment-authorized',
      color: green[500],
      tooltip: `Portal de proveedores: ${invoice.receiver.name}. Factura autorizada para pago rápido`,
      icon: <Bolt sx={{ width: 12, height: 12 }} />,
    },
  ];

  return (
    <AlertsGrid alerts={alertsArray} />
  );
};

ChileCommercialAlertsArray.propTypes = {
  invoice: InvoiceType.isRequired,
};

export default ChileCommercialAlertsArray;
