import React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import { ADD_OR_EDIT_WHITELIST, MASTER_ENTITY_WHITELISTS } from '../../graphql';

const SwitchComponent = ({ id, initialState }) => {
  const [editRateWhitelist, { loading }] = useMutation(
    ADD_OR_EDIT_WHITELIST,
    {
      variables: {
        whitelistId: id,
        active: !initialState,
      },
      refetchQueries: [MASTER_ENTITY_WHITELISTS],
    },
  );
  return (
    <LoadingButton
      onClick={editRateWhitelist}
      loading={loading}
      variant="contained"
      disabled={false}
      size="small"
    >
      {initialState ? 'Desactivar' : 'Activar'}
    </LoadingButton>
  );
};

SwitchComponent.propTypes = {
  id: PropTypes.string.isRequired,
  initialState: PropTypes.bool.isRequired,
};

export default SwitchComponent;
