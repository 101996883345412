import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { getTimeDiffWithHoursMinutes } from '../../helpers';

const TimeInStageCell = ({ time }) => (
  <Typography variant="body2">{getTimeDiffWithHoursMinutes(moment(time))}</Typography>
);

TimeInStageCell.propTypes = {
  time: PropTypes.string.isRequired,
};

export default TimeInStageCell;
