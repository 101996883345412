import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const TextCell = ({ text, ...props }) => <Typography {...props}>{text}</Typography>;

TextCell.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextCell;
