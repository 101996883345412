import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import Delete from '@mui/icons-material/Delete';
import { green, red, grey } from '@mui/material/colors';

const ThreeActionsButtonsCell = ({ onAccept, onReject, onDelete, ...props }) => (
  <Grid container {...props}>
    <IconButton onClick={onAccept}>
      <CheckCircle sx={{ color: green[700] }} />
    </IconButton>
    <IconButton onClick={onReject}>
      <Cancel sx={{ color: red[700] }} />
    </IconButton>
    <IconButton onClick={onDelete}>
      <Delete sx={{ color: grey[700] }} />
    </IconButton>
  </Grid>
);

ThreeActionsButtonsCell.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ThreeActionsButtonsCell;
