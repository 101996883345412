import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { numberToMoney } from '../../helpers';

const ProgressBarCell = (
  { value, isMoney, currency, total, startTooltipTitle, endTooltipTitle, ...props },
) => {
  const showPercentage = useMemo(() => (
    total ? (value * 100) / total : value
  ), [value, total, isMoney]);
  const fillPercentage = useMemo(() => Math.min(
    100, (
      total ? (value * 100) / total : value
    ),
  ), [value, total, isMoney]);

  return (
    <Stack
      direction="column"
      spacing={2}
      width="100%"
      alignItems={!isMoney && 'center'}
      {...props}
    >
      <LinearProgress
        sx={{ minWidth: '100%', minHeight: 8, backgroundColor: '#E1E1E1' }}
        variant="determinate"
        value={fillPercentage}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
      >
        {isMoney ? (
          <>
            <Typography whiteSpace="pre">
              {`${startTooltipTitle}: \n${numberToMoney(value, currency)}`}
            </Typography>
            <Typography whiteSpace="pre">
              {`${endTooltipTitle}: \n${numberToMoney(total, currency)}`}
            </Typography>
          </>
        ) : (
          <Typography>{Math.trunc(showPercentage)}%</Typography>
        )}
      </Stack>
    </Stack>
  );
};

ProgressBarCell.propTypes = {
  value: PropTypes.number.isRequired,
  total: PropTypes.number,
  isMoney: PropTypes.bool,
  currency: PropTypes.string,
  startTooltipTitle: PropTypes.string,
  endTooltipTitle: PropTypes.string,
};

ProgressBarCell.defaultProps = {
  total: 100,
  isMoney: false,
  currency: '',
  startTooltipTitle: '',
  endTooltipTitle: '',
};

export default ProgressBarCell;
